(function ($) {

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function share_buttons(element, delay) {
  	var $selector;
  	var $sibs;

  	if (element.parents('.field-name-share-button').length > 0) {
  		$selector = element.parents('.field-name-share-button');
    }
    else {
		  $selector = element;
    }

    $sibs = $selector.siblings('.social-share');
    //Comment out the switch for hover and click. Only handle click.
    /*if (delay == 'delay' && $sibs.is(':hidden')) {
		  $sibs.delay(300).slideDown();
    }
    else if (delay == 'no-delay' && $sibs.is(':hidden') && Modernizr.touch) {
    	$sibs.slideDown();
    }
    else if (delay == 'no-delay' && $sibs.is(':visible')) {
		  $sibs.slideUp();
    }*/
    if ($sibs.is(':hidden')) {
      $sibs.slideDown();
    }
    else {
      $sibs.slideUp();
    }
  }

  // Fade in the sliders in .8 seconds
  function lazyloadSlider(slider, target) {
  	$(slider).on('init', function(slick){
  		if (target === undefined) {
  			var showTarget = $(this);
  		} else {
  			var showTarget = $(target);
  		}
  		showTarget.fadeIn(800);
  	});
  }

  Drupal.behaviors.generalActions = {
  	attach: function (context, settings) {
  		'use strict';
      $(function(){
        $('html').removeClass('no-js');

        // Use the persona cookie to create a <body> data attribute so css can use it
        var persona = getCookie("persona");
        persona = persona.length ? persona : "future-student";
        $( "body" ).attr('data-persona', persona);
      });

      $( ".menu-toggle" ).click(function() {
        $( 'body' ).toggleClass( "menu-open" );
        $( 'body' ).removeClass( "persona-open" );
      });
      $( ".search-toggle, .search .close" ).click(function() {
        $( 'body' ).toggleClass( "search-open" );
        $( 'body' ).removeClass( "persona-open" );
        $('.auto-results').hide();
      });
      $( ".persona-toggle" ).click(function() {
        $( '.persona-toggle, .persona-menu' ).toggleClass( "persona-open" );
        $( '.personas' ).toggleClass( "persona-open" );
        $( 'body' ).removeClass( "search-open" );
		    $( 'body' ).removeClass( "menu-open" );
      });
      $( ".nav-toggle" ).click(function() {
        $( 'body' ).toggleClass( "nav-open" );
      });

      $( ".wrap" ).click(function() {
        $( '.personas' ).removeClass( "persona-open" );
      });

      $( ".emergency-message" ).click(function() {
        $( this ).toggleClass( "closed" );
      });

      $('.selectpicker').selectpicker({
        style: 'btn btn-dropdown btn-gray',
        title: 'Select a Program'
      });


      //Add smooth scrolling for anchor links, but not for modal links.
      $('a[href*="#"]:not([href="#"]):not(.modal-link)').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top - 110
            }, 1500);
            return false;
          }
        }
      });

      //Login Modal
      $('a.login-link, li.login-link a').click(function(e) {
        e.preventDefault();
        $('#login-modal').modal();
        $( 'body' ).removeClass( "menu-open" );
      });

      //Request Info Modal
      $('a.reqinfo-link, li.reqinfo-link a').click(function(e) {
        e.preventDefault();
        $('#reqinfo-modal').modal();
        $( 'body' ).removeClass( "menu-open" );
      });
  	}
  }

  Drupal.behaviors.personaCookie = {
    attach: function (context, settings) {
      //Set or change Persona Cookie value when persona link clicked
      $('.persona-links a').click(function(e) {
        var persona = $(this).attr('class').replace('btn ','');
        setCookie('persona', persona, 10000);
      });
    }
  }

  Drupal.behaviors.socialShareToggle = {
    attach: function (context, settings) {
			$('.block-linkedin-company-shares .share, .video .share, .node-tb-social-feed .share').click(function () {
				share_buttons($(this), 'no-delay');
			});
      //Comment out the hover state of social shares
      /*.mouseenter(function() {
				share_buttons($(this), 'delay');
			});*/
		}
  };

  Drupal.behaviors.socialShareAlter = {
    attach: function (context, settings) {
		$( ".social-share-facebook" ).html('<div class="icon icon-facebook-f"></div>').removeClass('social-share-facebook').addClass('social-icon');
		$( ".social-share-email" ).html('<div class="icon icon-send"></div>').removeClass('social-share-email').addClass('social-icon');
		$( ".social-share-linkedin" ).html('<div class="icon icon-linkedin"></div>').removeClass('social-share-linkedin').addClass('social-icon');
		$( ".social-share-twitter" ).html('<div class="icon icon-twitter"></div>').removeClass('social-share-twitter').addClass('social-icon');
	}
  };

  Drupal.behaviors.socialSlickSlide = {
    attach: function (context, settings) {

		  lazyloadSlider('.social-slick.all-items > .view-content', 'section.connected .social-slick');

  		$( ".social-slick.all-items > .view-content" ).slick({
  			infinite: true,
  			speed: 300,
  			adaptiveHeight: true,
  			slidesToShow: 2,
  			centerMode: true,
  			initialSlide : 1,
  			appendArrows: $('.view-social-wall-interior.all-items .social-arrow'),
  			responsive: [{
  				breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
  			}]
  		});

  		$( ".social-slick.flickr > .view-content" ).slick({
  			infinite: true,
  			speed: 300,
  			adaptiveHeight: true,
  			slidesToShow: 2,
  			centerMode: true,
  			initialSlide : 1,
  			appendArrows: $('.view-social-wall-interior.flickr .social-arrow'),
  			responsive: [{
  				breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
  			}]
  		});

  		$( ".social-slick.twitter > .view-content" ).slick({
  			infinite: true,
  			speed: 300,
  			adaptiveHeight: true,
  			slidesToShow: 3,
  			centerMode: true,
  			initialSlide : 1,
  			appendArrows: $('.view-social-wall-interior.twitter .social-arrow'),
  			responsive: [{
  				breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
  			}]
  		});

  		$( ".social-slick.facebook > .view-content" ).slick({
  			infinite: true,
  			speed: 300,
  			adaptiveHeight: true,
  			slidesToShow: 3,
  			centerMode: true,
  			initialSlide : 1,
  			appendArrows: $('.view-social-wall-interior.facebook .social-arrow'),
  			responsive: [{
  				breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
  			}]
  		});

  		/*$( "#block-quicktabs-stay-connected-interior .block-linkedin-company-shares .items.carousel" ).slick({
  			infinite: true,
  			speed: 300,
  			adaptiveHeight: true,
  			slidesToShow: 3,
  			centerMode: true,
  			initialSlide : 3,
  			appendArrows: $('.block-linkedin-company-shares .social-arrow'),
  			responsive: [{
  				breakpoint: 768,
          settings: {
            slidesToShow: 1,
          }
  			}]
  		});*/

  		//This enables the hidden slideshows to get calculated once they are displayed
  		$('#block-quicktabs-stay-connected-int-no-linkedin .quicktabs-tab').click(function() {
  			$('.social-slick.all-items > .view-content, .social-slick.flickr > .view-content, .social-slick.twitter > .view-content, .social-slick.facebook > .view-content').slick('setPosition');
  		});

      // Comment out LinkedIn version. Opens the door to bring back at some point.
      /*
      $('#block-quicktabs-stay-connected-interior .quicktabs-tab').click(function() {
        $('.social-slick.all-items > .view-content, .social-slick.flickr > .view-content, .social-slick.twitter > .view-content, .social-slick.facebook > .view-content, #block-quicktabs-stay-connected-interior .block-linkedin-company-shares .items.carousel').slick('setPosition');
      });*/
    }
  };

  Drupal.behaviors.timelineSlider = {
    attach: function (context, settings) {
    	var timelinecarousel = $('.timeline-carousel');
      var timeline_arrows = $('.timeline-arrows');
      var timeline_dots = $('.timeline-dots');

      //Timeline hover action
      timeline_dots.on({
        mouseenter: function() {
          var $index = parseInt($(this).children().text()) - 1;
          $(this).append('<div class="timeline-dot-date">'+$('.view-timeline .slick-list').find('.item[data-slick-index='+$index+'] .date').text()+'</div>');
        },
        mouseleave: function() {
          $(this).children('.timeline-dot-date').remove();
        }
      }, '.slick-dots li');

  	  lazyloadSlider(timelinecarousel);

      timelinecarousel.slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        appendArrows: timeline_arrows,
        appendDots: timeline_dots,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      });
    }
  }

  Drupal.behaviors.factSlider = {
  	attach: function (context, settings) {
  		var factcarousel = $('.fact-carousel');
  	  var arrows = $('.facts .arrow');

  	  lazyloadSlider(factcarousel);

  	  factcarousel.slick({
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '0',
        variableWidth: true,
        appendArrows: arrows,
      });

      /* It's here that we define all of our keyframes */
      if (factcarousel.length) {
        var unique_items = [];
        $('.fact-carousel .item .donut').each(function() {
          var data_value = parseInt($(this).attr('data-value'));
          if (unique_items.indexOf(data_value) === -1) {
            unique_items.push(data_value);
          }
        });

        var arraylength = unique_items.length;
        var offsetval, unique_key;
        var keyname = 'donut-';
        for (var i=0; i < arraylength; i++) {
          offsetval = 0;
          unique_key = keyname + String(unique_items[i]);
          if (unique_items[i] !== 100) {
            offsetval = Math.round((377 * (100 - unique_items[i]) / 100));
          }
          $.keyframe.define([{
            name: unique_key,
            'to': {'stroke-dashoffset': offsetval}
          }]);
        }
        $.keyframe.define([{
          name: 'ie-donut',
          '0%': {'transform': 'rotate(0deg)'},
          '100%': {'transform': 'rotate(360deg)'}
        }]);
      }

      factcarousel.bind('inview', function (event, visible) {
        if (visible == true) {
          // element is now visible in the viewport
          $('.fact-carousel .item').each(function() {
            var counter = $(this).find('h2 span');
            var donut = $(this).find('.donut');
            counter.prop('Counter', 0).animate({
              Counter: counter.text()
            }, {
              duration: 1000,
              easing: 'swing',
              step: function(now) {
                counter.text(Math.ceil(now));
              }
            });
            if (donut.length) {
              var keyval = 'donut-' + donut.attr('data-value');
              $('.donut.' + keyval + ' svg .circle_animation').playKeyframe({
                name: keyval,
                duration: '1s',
                timingFunction: 'ease-out',
                fillMode: 'forwards',
                complete: function(){}
              });
              $('.no-smil .donut').playKeyframe({
                name: 'ie-donut',
                duration: '1s',
                timingFunction: 'ease-out',
                fillMode: 'forwards',
                complete: function(){}
              });
              $('.no-smil .donut.' + keyval + ' svg .circle_animation').css({
                'stroke-dasharray': 377,
                'stroke-dashoffset': parseInt(donut.attr('data-value'))
              });
            }
          });
        }
      });
      factcarousel.trigger('inview');
  	}
  }

  Drupal.behaviors.videoHandling = {
  	attach: function (context, settings) {
  		var videocarousel = $('.video-carousel');
  		var arrows2 = $('.video .arrows');

  		lazyloadSlider(videocarousel);

  		videocarousel.slick({
        centerMode: false,
        centerPadding: '0',
        slidesToShow: 2,
        appendArrows: arrows2,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      });

      //Need to move the modals from inside the slick items before we can open them.
      $('a.modal-link').click(function(e) {
        e.preventDefault();
        var $title = $(this).parent().siblings('.video-title').text();
        var $src = $(this).parent().siblings('.video-youtube').text();
        var $body = $(this).parent().siblings('.video-body').text();

        $('#video-modal h3').text($title);
        $('#video-modal .modal-body div.video-description').text($body);
        $('#video-modal iframe').attr('src', $src);
        $('#video-modal').modal();
      });

      //Make sure to turn off the video when modal is closed
      $("#video-modal").on('hide.bs.modal', function(){
        $("#yt-embed").attr('src', '');
      });

      // Homepage background video
  		$('.bg-video').YTPlayer({
        fitToBackground: true,
        repeat:true,
        videoId: 'tFKxAPuHGx0',
        playerVars: {
          autoplay: 1,
          controls: 0,
          showinfo: 0,
          wmode: 'transparent',
          branding: 0,
          rel: 0,
          autohide: 1,
          loop:1,
          playlist: 'tFKxAPuHGx0'
        }
      });
  	}
  };

  Drupal.behaviors.eventHandling = {
  	attach: function (context, settings) {
  		var eventcarousel = $('.event-carousel');
  		var event_arrows = $('.events .event-arrows');

  		lazyloadSlider(eventcarousel);

  		eventcarousel.slick({
        infinite: true,
        slidesToShow: 3,
        centerMode: false,
        centerPadding: '0',
        appendArrows: event_arrows,
        responsive: [
	        {
	          breakpoint: 992,
	          settings: {
	            slidesToShow: 2,
	          }
	        },
	        {
	          breakpoint: 767,
	          settings: {
	            slidesToShow: 1,
	          }
	        }
      	]
      });
  	}
  }

  Drupal.behaviors.offCanvas = {
  	attach: function (context, settings) {
  		var $body = $('body');
  		var $heading = $('.canvas-wrap h2');
  		var $content_section = $('.canvas-wrap .canvas-content');

  		//Populate the off canvas section and open it
  		$('.canvas-boxes .item .inner').click(function(e) {
  			e.stopPropagation();
        var $title = $(this).find('h4').text();
        var $content = $(this).find('.off-canvas-content').html();
        $heading.text($title);
        $content_section.html($content);
        $body.addClass('canvas-open');
      });

  		//Closing the section based on the action
      $('.canvas-wrap .close, .wrap, .header').click(function(e) {
        $body.removeClass('canvas-open');
      });

      $(document).keyup(function(e) {
      	if (e.keyCode == 27 && $body.hasClass('canvas-open')) {
      		$body.removeClass('canvas-open');
      	}
      });
  	}
  }

  Drupal.behaviors.searchActions = {
    attach: function (context, settings) {
    	var $search_box = $('.search-box .keyword-input');
    	var $header_box = $('.search .search-input');
    	var $search_submit = $('.search-box #onpage-search');
    	var $results_block = $('div.search-results');

    	//Autocompletes
    	var $onpage_auto = $('.search-box .auto-results');
    	var $header_auto = $('.search .auto-results');
    	var $onpage_auto_items = $('.search-box .auto-results li');

    	$search_box.keyup(function(e) {
	    	if(e.keyCode == 13){
	        $search_submit.click();
	      }
	    });

      $search_submit.click(function(e) {
        e.preventDefault();
        $onpage_auto.hide();
        var $keyword_val = $search_box.val().replace('/',' ');
        if($keyword_val.length) {
        	$results_block.html('<div class="spinner"><i class="icon-spinner"></i></div>');
        	$.ajax({
        		url: '/search-results/' + $keyword_val + '/ajax',
        		success: function(data) {
        			$results_block.html(data);
        		}
        	});
        }
      });

      //Autocomplete

      function autocomplete(selector, results) {
      	results.hide();
      	var $keyword_val = selector.val().replace('/',' ');
      	if ($keyword_val.length >= 2) {
     			$.ajax({
     				url: '/search-results/' + $keyword_val + '/auto',
     				success: function(data) {
     					if (data) {
     						results.html(data);
     						results.show();
     					}
     				}
     			});
     		}
      }

      //Autocomplete action for on page search box
      $search_box.on('input',function(e){
      	autocomplete($(this), $onpage_auto);
    	});

      //Autocomplete action for header search box
      $header_box.on('input',function(e){
      	autocomplete($(this), $header_auto);
    	});

      //On page autocomplete result click
    	$onpage_auto.on('click', 'li.item', function() {
    		$search_box.val($(this).text());
    		$search_submit.click();
    	});

    	//Header autocomplate result click
    	$header_auto.on('click', 'li.item', function() {
    		$header_box.val($(this).text());
    		$('.search form').submit();
    	});
    }
  };
})(jQuery);
